// export const BASEURL = 'http://localhost:5055'
export const BASEURL = 'https://ido.luvnft.com'
export const NETWORKNAME = 'testnet'
export const DECIMAL = 6;
export const DECIMAL_8 = 8;
export const TOKEN_NAME = '$RNT'
export const SALE_TYPE = {
  public: 'public',
  whitelist: 'whitelist'
}
